import clsx from "clsx";
import React from "react";
import { motion } from "framer-motion";
import { makeStyles, alpha } from "@material-ui/core/styles";
import { Alert, AlertTitle, Box } from "@material-ui/core";
import { Color } from "@material-ui/core/Alert";
import { Block } from "@material-ui/icons";

const TRANSITION = {
  ease: "linear",
  duration: 3.2,
  loop: Infinity,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    top: 0,
    left: 0,
    width: '100%',
    position: 'fixed',
    zIndex: theme.zIndex?.snackbar,
    boxShadow: `0 0 2px ${theme.palette?.primary.main}`
  },
  rootNavBar: {
    height: "100%",    
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    top: 0,
    left: 0,
    width: '100%',
    position: 'fixed',
    boxShadow: `0 0 2px ${theme.palette?.primary.main}`
  },
  box: {
    position: "absolute",
    borderRadius: "25%",
  },
  inner: {
    width: 100,
    height: 100,
    border: `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
  },
  outside: {
    width: 120,
    height: 120,
    border: `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
  },
}));

export type LoadingScreenprops = {
  className?: string;
  message?: string;
  messageType?: string;
  messageTitle?: string;
};

function LoadingScreen({ className, message, messageType, messageTitle }: LoadingScreenprops) {
  const classes = useStyles();
  const color = messageType as Color ?? 'error';
  let logoMarginTop = '0%';
  const logoMarginBottom = message ? '170%' : '0%';

  if(!!messageTitle && !!message)
    logoMarginTop = '285%';
  else if(!!message)
    logoMarginTop = '235%';

  return (
      <div className={clsx(classes.rootNavBar, className)}>
        <motion.div
          initial={{ rotateY: 0 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            flip: Infinity,
            repeatDelay: 1,
          }}
        >
          <Box
            component="img"
            alt="logo"
            src='/static/images/loading_logo.png'
            height={28}
            sx={{ height: 64, marginTop: logoMarginTop, marginBottom: logoMarginBottom }}
          />
        </motion.div>

        <motion.div
          animate={{
            scale: [1.2, 1, 1, 1.2, 1.2],
            rotate: [270, 0, 0, 270, 270],
            opacity: [0.25, 1, 1, 1, 0.25],
            borderRadius: ["25%", "25%", "50%", "50%", "25%"],
          }}
          transition={TRANSITION}
          className={clsx(classes.box, classes.inner)}
        />

        <motion.div
          animate={{
            scale: [1, 1.2, 1.2, 1, 1],
            rotate: [0, 270, 270, 0, 0],
            opacity: [1, 0.25, 0.25, 0.25, 1],
            borderRadius: ["25%", "25%", "50%", "50%", "25%"],
          }}
          transition={{
            ease: "linear",
            duration: 3.2,
            loop: Infinity,
          }}
          className={clsx(classes.box, classes.outside)}
        />
        {message && <Alert severity={'error'} sx={{marginTop:'0%'}} >
          {!!messageTitle && <AlertTitle>{messageTitle}</AlertTitle>}
          {message}
        </Alert>}
      </div>

  );
}

export default LoadingScreen;
