import { useDispatch } from "react-redux";
import { LanguageTypeEnum } from "src/apis/data-contracts";
// import { DateTime } from "luxon";

export function useResourceAction() {
  const dispatch = useDispatch();

  return {
    fetchUserResource: (
      resourceId?: string,
      ProfileImageUrl?: string,
      firstName?: string,
      lastName?: string,
      name?: string,
      resourceType?: "employee" | "candidate" | "client",
      status?: string,
      resourceEmployeeIdLogged?: string,
      language?: LanguageTypeEnum
    ) => {
      dispatch({
        type: "FETCH-USER-RESOURCE",
        data: {
          resourceId,
          ProfileImageUrl,
          firstName,
          lastName,
          name,
          resourceType,
          status,
          resourceEmployeeIdLogged,
          language,
        },
      });
    },

    fetchUserResourceRole: (role?: string) => {
      dispatch({
        type: "FETCH-USER-RESOURCE",
        data: {
          role,
        },
      });
    },

    fetchUserResourceProfileImageUrl: (ProfileImageUrl?: string) => {
      dispatch({
        type: "FETCH-USER-RESOURCE",
        data: {
          ProfileImageUrl,
        },
      });
    },
    fetchUserEmailResource: (emails: []) => {
      dispatch({
        type: "FETCH-USER-EMAIL-RESOURCE",
        data: {
          emails,
        },
      });
    },

    logoff: () => {
      dispatch({ type: "USER-RESOURCE-LOGOFF" });
    },
  };
}
