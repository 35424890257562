import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {PATH_PAGE} from 'src/routes/paths';
import {DateTime} from "luxon";
import { isChrome, isEdge, isMobileSafari, isSafari } from "react-device-detect";
import LoadingScreen from 'src/components/LoadingScreen';
import { useTranslation } from 'react-i18next';

export type AuthProtectProps = {
  children?: React.ReactNode
};

function AuthProtect({children}: AuthProtectProps) {
  const {loggedIn, expiresAt} = useSelector((state: any) => state.authLoginReducer);
  const { t, i18n } = useTranslation();

  if (!loggedIn || DateTime.fromISO(expiresAt) < DateTime.now()) {
    return <Redirect to={PATH_PAGE.auth.login}/>;
  }

  if(!isEdge && !isChrome && !isSafari && !isMobileSafari){
    return <LoadingScreen message={t("common.browserNotSupported") + " Google Chrome, Edge " + t("common.or") + " Safari."} messageTitle={t("common.improveYourExperience")} />;
  }

  return <>{children}</>;
}

export default AuthProtect;
