import clsx from "clsx";
import React, {forwardRef} from "react";
import {ButtonAnimate} from "src/components/Animate";
import {alpha, makeStyles} from "@material-ui/core/styles";
import {capitalize} from "@material-ui/core/utils";
import {IconButton} from "@material-ui/core";
import {PaletteColor} from "@material-ui/core/styles/createPalette";

const useStyles = makeStyles((theme) => {
  const createStyle = (color: PaletteColor) => {
    return {
      color: color.main,
      "&:hover": {
        backgroundColor: alpha(
          color.main,
          theme.palette.action.hoverOpacity
        ),
      },
    };
  };

  return {
    colorInfo: createStyle(theme.palette.info),
    colorSuccess: createStyle(theme.palette.success),
    colorWarning: createStyle(theme.palette.warning),
    colorError: createStyle(theme.palette.error),
    colorWhite: {
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity
        ),
      },
    },
  };
});

export type MIconButtonProps = {
  children?: React.ReactNode,
  classes?: any,
  className?: string,
  size?: 'small' | 'medium',
  onClick?: () => void,
  color?: "default" | "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "white",
  edge?: any,
}

const MIconButton = forwardRef((props: MIconButtonProps, ref: any) => {
    const {color = "default", children, className, size, onClick, edge} = props;
    const classes = useStyles();

    if (
      color === "inherit" ||
      color === "primary" ||
      color === "secondary"
    ) {
      return (
        <ButtonAnimate>
          <IconButton ref={ref} color={color} className={className} size={size} onClick={onClick} edge={edge}>
            {children}
          </IconButton>
        </ButtonAnimate>
      );
    } else if (color === "default") {
      return (
        <ButtonAnimate>
          <IconButton ref={ref} style={{color: '#637381'}} className={className} size={size} onClick={onClick} edge={edge}>
            {children}
          </IconButton>
        </ButtonAnimate>
      )
    }

    return (
      <ButtonAnimate>
        <IconButton
          ref={ref}
          edge={edge}
          className={clsx(
            {
              // @ts-ignore
              [classes[`color${capitalize(color)}`]]: color !== "default",
            },
            className
          )}
        >
          {children}
        </IconButton>
      </ButtonAnimate>
    );
  }
);

export default MIconButton;
