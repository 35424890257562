import clsx from "clsx";
import React, { forwardRef } from "react";
import { Avatar } from "@material-ui/core";
import { capitalize } from "@material-ui/core/utils";
import { makeStyles } from "@material-ui/core/styles";
import { PaletteColor } from "@material-ui/core/styles/createPalette";

const useStyles = makeStyles((theme) => {
  const createStyle = (color: PaletteColor) => {
    return {
      color: `${color.contrastText} !important`,
      backgroundColor: `${color.main} !important`,
    };
  };

  return {
    root: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    colorPrimary: createStyle(theme.palette.primary),
    colorSecondary: createStyle(theme.palette.secondary),
    colorInfo: createStyle(theme.palette.info),
    colorSuccess: createStyle(theme.palette.success),
    colorWarning: createStyle(theme.palette.warning),
    colorError: createStyle(theme.palette.error),
  };
});

export type MAvatarProps = {
  children?: React.ReactNode,
  classes?: object,
  className?: string,
  src?: string,
  alt?: string,
  color?:
    "default" |
    "primary" |
    "secondary" |
    "info" |
    "success" |
    "warning" |
    "error",
};

const MAvatar = forwardRef(
  ({ color = "default", children, className, src, alt }: MAvatarProps, ref: any) => {
    const classes = useStyles();

    return (
      <Avatar
        ref={ref}
        src={src}
        alt={alt}
        className={clsx(
          classes.root,
          {
            // @ts-ignore
            [classes[`color${capitalize(color)}`]]: color,
          },
          className
        )}
      >
        {children}
      </Avatar>
    );
  }
);

export default MAvatar;
