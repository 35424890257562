import {useSelector} from 'react-redux';
import {useSettingsAction} from 'src/redux/actions/settings/settingsAction';

function useSettings() {
  const settingsAction = useSettingsAction();
  const {themeMode, themeDirection} = useSelector((state: any) => state.settings);

  const handleChangeTheme = (event: any) => (
    event.target.value === 'light' ? settingsAction.lightMode() : settingsAction.darkMode()
  )

  const handleChangeDirection = (event: any) => (
    event.target.value === 'ltr' ? settingsAction.onRight() : settingsAction.onLeft()
  )

  return {
    // Mode
    themeMode: themeMode,
    selectMode: handleChangeTheme,
    // Direction
    themeDirection: themeDirection,
    selectDirection: handleChangeDirection
  };
}

export default useSettings;
