import { useDispatch } from "react-redux";
import { DateTime } from "luxon";

export function useAuthLoginAction() {
  const dispatch = useDispatch();

  return {
    login: (
      accessToken: string,
      refreshToken: string,
      expiresIn: number,
    ) => {
      let expiresAt = DateTime.now();
      expiresAt = expiresAt.plus(expiresIn * 1000);

      dispatch({
        type: "LOGIN",
        data: {
          accessToken: accessToken,
          refreshToken: refreshToken,
          expiresIn: expiresIn,
          expiresAt: expiresAt,
        },
      });
    },
    logoff: () => {
      dispatch({ type: "LOGOFF" });
    },
  };
}
