import clsx from 'clsx';
import React from 'react';
import {motion} from 'framer-motion';
import {varSmallClick} from './variants';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex'
  }
}));

export type ButtonAnimateProps = {
  small?: boolean,
  children?: React.ReactNode,
  className?: string
}

function ButtonAnimate(props: ButtonAnimateProps) {
  const {children, className} = props
  const classes = useStyles();

  return (
    <Box
      component={motion.div}
      whileTap="tap"
      whileHover="hover"
      variants={varSmallClick}
      className={clsx(classes.root, className)}
    >
      {children}
    </Box>
  );
}

export default ButtonAnimate;
