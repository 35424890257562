import { AuthApi } from "src/apis/nbauth-api/nbAuthApi";
import { useAuthLoginAction } from "src/redux/actions/auth/authLoginAction";
import { useResourceAction } from "src/redux/actions/user/userResourceAction";
import { ResourceApi } from "src/apis/niki-api/api/ResourceApi";
import { EmailModelApiModel } from "src/apis/niki-api/model/EmailModelApiModel";
import { CompanyApi } from "src/apis/niki-api/api/CompanyApi";
import { useCompanyInfoAction } from "src/redux/actions/company/useCompanyInfoAction";
import { useProfileController } from "./useProfileController";
import { LanguageTypeEnum } from "src/apis/data-contracts";

interface UseAuthControllerParams {}

export function useAuthController(props?: UseAuthControllerParams) {
  const {} = props ?? {};
  const loginAction = useAuthLoginAction();
  const resourceAction = useResourceAction();
  const companyAction = useCompanyInfoAction();
  let accessToken = "";
  const { resourceEmployeeDetail } = useProfileController();

  return {
    login: async (username: string, password: string) => {
      await AuthApi.authLoginCredentials(username, password).then((authData) => {
        let accessData = {
          accessToken: authData["access_token"],
          refreshToken: authData["refresh_token"],
          expiresIn: authData["expires_in"],
        };
        accessToken = accessData.accessToken;

        loginAction.login(accessData.accessToken, accessData.refreshToken, accessData.expiresIn);
      });

      const resourceApi = new ResourceApi(accessToken);
      let language = LanguageTypeEnum.EsAR; // default language if nothing is found;

      await resourceApi.resourceGet().then((response) => {
        let resourceId = response?.resourceId ?? "";
        let resourceEmployeeIdLogged = response?.resourceEmployeeId ?? "";
        let ProfileImageUrl = response.personalInfo.profileImageUrl;
        let firstName = response.personalInfo.firstName;
        let lastName = response.personalInfo.lastName;
        let name = lastName ? firstName + " " + lastName : firstName;
        let status = response.status ?? "";
        language = response?.language;

        let resourceType: "employee" | "candidate" | "client" | undefined = undefined;
        switch (response.type) {
          case "Employee":
            resourceType = "employee";
            break;
          case "Candidate":
            resourceType = "candidate";
            break;
          case "Client":
            resourceType = "client";
            break;
        }

        resourceAction.fetchUserEmailResource(
          response.emails.map((item: EmailModelApiModel) => ({
            address: item.address,
            confirmed: item.confirmed,
            main: item.main,
            status: item.status,
            type: item.type,
          }))
        );

        resourceAction.fetchUserResource(resourceId, ProfileImageUrl, firstName, lastName, name, resourceType, status, resourceEmployeeIdLogged, language);

        //  alert(resourceId)
        resourceEmployeeDetail(resourceEmployeeIdLogged).then((data) => {
          let role = data?.attributes?.find((a) => a.key === "Role")?.value ?? "";
          resourceAction.fetchUserResourceRole(role);
        });
      });

      const companyApi = new CompanyApi(accessToken);

      companyApi.companyInfoGet().then((response) => {
        language = language ?? response.language;
        companyAction.fetchUserResource(response.domain, response.name, response.status, response.type, response.logoUrl, language);
      });
    },

    logoff: async () => {
      loginAction.logoff();
      resourceAction.logoff();
      companyAction.logoff();
    },
  };
}
