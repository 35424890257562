import { useDispatch } from "react-redux";
import { LanguageTypeEnum } from "src/apis/data-contracts";

export function useCompanyInfoAction() {
  const dispatch = useDispatch();

  return {
    fetchUserResource: (domain: string, name: string, status: string, type: string, logoUrl: string, language?: LanguageTypeEnum) => {
      dispatch({
        type: "FETCH-COMPANY-INFO",
        data: {
          domain,
          name,
          status,
          type,
          logoUrl,
          language,
        },
      });
    },
    logoff: () => {
      dispatch({ type: "COMPANY-INFO-LOGOFF" });
    },
  };
}
