import { ResourceModel, SkillAverageModel, AssessmentModel, ResourcePreferencesModelResultHandler, ResourcePreferencesModel } from "src/apis/data-contracts";
import { AxiosMiddleware } from "src/apis/niki-api/middleware/AxiosMiddleware";
import { nikiAssessmentApiConfig } from "src/config";

export class ResourceApi {
  constructor(accessToken: string) {
    this._axiosMiddleware = new AxiosMiddleware({
      baseURL: nikiAssessmentApiConfig.baseURL,
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    });
  }

  private _axiosMiddleware: AxiosMiddleware;

  async resourceGet() {
    return await this._axiosMiddleware.get(`/api/user/me`);
  }

  async resourceAddressGet() {
    return await this._axiosMiddleware.get(`/api/resource/address`);
  }

  async resourceAddressDelete(addressId: string) {
    return await this._axiosMiddleware.delete(`/api/resource/address/${addressId}`);
  }

  async resourceDocumentGet() {
    return await this._axiosMiddleware.get(`/api/resource/document`);
  }

  async resourceDocumentDelete(documentId: string) {
    return await this._axiosMiddleware.delete(`/api/resource/document/${documentId}`);
  }

  async resourceEmailGet() {
    return await this._axiosMiddleware.get(`/api/resource/email`);
  }

  async resourceEmailDelete(emailId: string) {
    return await this._axiosMiddleware.delete(`/api/resource/email/${emailId}`);
  }

  async resourcePhoneGet() {
    return await this._axiosMiddleware.get(`/api/resource/phone`);
  }

  async resourcePhoneDelete(phoneId: string) {
    return await this._axiosMiddleware.delete(`/api/resource/phone/${phoneId}`);
  }

  async recourceNodeGet(resourceEmployeeId: string, typeId: number): Promise<ResourceModel | undefined> {
    return await this._axiosMiddleware.get(`/api/resource/${resourceEmployeeId}/node`, { params: { nodeType: typeId } });
  }

  async updatePreferences(preferences: ResourcePreferencesModel) {
    return await this._axiosMiddleware.put(`/api/resource/preferences`, preferences);
  }

  async getPreferences(): Promise<ResourcePreferencesModel | undefined> {
    return await this._axiosMiddleware.get(`/api/resource/preferences`);
  }
}
