import {AxiosMiddleware} from "src/apis/niki-api/middleware/AxiosMiddleware";
import {nikiAssessmentApiConfig} from "src/config";

export class CompanyApi {
  constructor(accessToken: string) {
    this._axiosMiddleware = new AxiosMiddleware({
      baseURL: nikiAssessmentApiConfig.baseURL,
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    });
  }

  private _axiosMiddleware: AxiosMiddleware;

  async companyInfoGet() {
    return await this._axiosMiddleware.get(`/api/company`);
  }
}
