import clsx from "clsx";
import React from "react";
import Account from "./Account";
import Languages from "./Languages";
import Notifications from "./Notifications";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Box, AppBar, Toolbar, Hidden, IconButton } from "@material-ui/core";
import { Icon } from "@iconify/react";
import menuFill from "@iconify-icons/eva/menu-fill";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backdropFilter: "blur(8px)",
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up("lg")]: {
      paddingLeft: DRAWER_WIDTH,
    },
  },
  toolbar: {
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: APPBAR_DESKTOP,
    },
  },
}));

export type TopBarProps = {
  className?: string;
  onOpenNav?: () => void;
};

function TopBar({ className, onOpenNav }: TopBarProps) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: "text.primary",
            }}
          >
            <Icon icon={menuFill} />
          </IconButton>
        </Hidden>

        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > *:not(:first-of-type)": {
              ml: {
                xs: 0.5,
                sm: 2,
                lg: 3,
              },
            },
          }}
        >
          <Languages />
          {/*<Notifications />*/}
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
