import NProgress from "nprogress";
import AppRoutes from "./AppRoutes";
import { PATH_PAGE } from "./paths";
import HomeRoutes from "./HomeRoutes";
import LoadingScreen from "src/components/LoadingScreen";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import React, { Suspense, Fragment, lazy, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GuardProtect } from "./GuardProtect";
import { StaticContext } from "react-router";

const nprogressStyle = makeStyles((theme) => ({
  "@global": {
    "#nprogress": {
      pointerEvents: "none",
      "& .bar": {
        top: 0,
        left: 0,
        height: 2,
        width: "100%",
        position: "fixed",
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
      },
      "& .peg": {
        right: 0,
        opacity: 1,
        width: 100,
        height: "100%",
        display: "block",
        position: "absolute",
        transform: "rotate(3deg) translate(0px, -4px)",
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
      },
    },
  },
}));

export type RouteProgressProps = {
  key?: React.Key | null;
  path?: any;
  exact?: boolean;
  render?: (
    props: RouteComponentProps<
      {
        [x: string]: string | undefined;
      },
      StaticContext,
      unknown
    >
  ) => React.ReactNode;
};

function RouteProgress({ key, path, exact, render }: RouteProgressProps) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false,
  });

  useEffect(() => {
    NProgress.done();
    return () => {
      NProgress.start();
    };
  }, []);

  return <Route key={key} path={path} exact={exact} render={render} />;
}

export function renderRoutes(routes: Array<Object> = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route exact path="/">
          <Redirect to={PATH_PAGE.auth.login}/>
        </Route>
        {routes.map((route: any, i: any) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props: any) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuardProtect,
    path: PATH_PAGE.auth.login,
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    path: PATH_PAGE.auth.loginUnprotected,
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    path: PATH_PAGE.auth.loginEmail,
    component: lazy(() => import("src/views/auth/LoginEmailView")),
  },
  {
    exact: true,
    guard: GuardProtect,
    path: PATH_PAGE.auth.register,
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    path: PATH_PAGE.auth.registerUnprotected,
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    path: PATH_PAGE.auth.forgotPassword,
    component: lazy(() => import("src/views/auth/ForgotPasswordView")),
  },
  {
    exact: true,
    path: PATH_PAGE.auth.verify,
    component: lazy(() => import("src/views/auth/VerifyCodeView")),
  },
  {
    exact: true,
    path: PATH_PAGE.auth.resetPassword,
    component: lazy(() => import("src/views/auth/ResetPasswordView")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/Page404View")),
  },
  {
    exact: true,
    path: "/500",
    component: lazy(() => import("src/views/errors/Page500View")),
  },
  {
    exact: true,
    path: PATH_PAGE.maintenance,
    component: lazy(() => import("src/views/pages/MaintenanceView")),
  },
  {
    exact: false,
    path: PATH_PAGE.auth.root,
    component: () => <Redirect to={PATH_PAGE.auth.login} />,
  },

  // App Routes
  AppRoutes,

  // Home Routes
  HomeRoutes,
];

export default routes;
