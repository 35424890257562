import clsx from "clsx";
import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import PopoverMenu from "src/components/PopoverMenu";
import { makeStyles } from "@material-ui/core/styles";
import { Box, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { MIconButton } from "src/theme";

const LANGS = [
  {
    value: "en_us",
    label: "English",
    icon: "/static/icons/ic_flags_en_us.svg",
  },
  {
    value: "es_ar",
    label: "Español",
    icon: "/static/icons/ic_flag_es.svg",
  },
  {
    value: "pt_br",
    label: "Português",
    icon: "/static/icons/ic_flag_pt_br.svg",
  },
  {
    value: "enUS",
    label: "English",
    icon: "/static/icons/ic_flags_en_us.svg",
  },
  {
    value: "esAR",
    label: "Español",
    icon: "/static/icons/ic_flag_es.svg",
  },
  {
    value: "ptBR",
    label: "Português",
    icon: "/static/icons/ic_flag_pt_br.svg",
  },
];

const useStyles = makeStyles((theme) => ({
  btnLang: {
    padding: 0,
    width: 44,
    height: 44,
  },
  isSelected: {
    // @ts-ignore
    backgroundColor: theme.palette.background.selected,
  },
}));

function Languages() {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage);

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then();
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        className={clsx(classes.btnLang, { [classes.isSelected]: isOpen })}
      >
        <img
          // @ts-ignore
          src={currentLang?.icon}
          // @ts-ignore
          alt={currentLang?.label}
          style={{ height: "22px", width: "32px" }}
        />
      </MIconButton>

      <PopoverMenu
        width={200}
        open={isOpen}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {LANGS.filter((lang) => {return lang.value.indexOf('_') > -1}) .map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === langStorage}
              onClick={() => handleChangeLanguage(option.value)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box
                  component="img"
                  alt={t(`language.${option.value}`)}
                  src={option.icon}
                  style={{ height: "20px", width: "30px" }}
                />
              </ListItemIcon>

              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Languages;
