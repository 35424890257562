import {combineReducers} from 'redux';
import authLoginReducer from './auth/authLoginReducer';
import settingsReducer from './settings/settingsReducer';
import {userResourceReducer} from './user/userResourceReducer';
import notificationsReducer from '../slices/notifications';
import {companyInfoReducer} from './company/useCompanyInfoReducer';

const rootReducer = combineReducers({
  authLoginReducer: authLoginReducer,
  settings: settingsReducer,
  user: userResourceReducer,
  company: companyInfoReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
