import React from 'react';

export type ImageProps = {
  src: string;
  alt?: string;
  className?: string;
}

export const Image = (props: ImageProps) => {
  const { src, alt, className } = props;

  return (
    <img src={src} alt={alt} className={className} />
  )
}