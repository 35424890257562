import React from 'react';
import {Box, Hidden} from '@material-ui/core';

export type LogoProps = {
  className?: string,
  sx?: any,
  url?:string,
  smallerLogo?: boolean,
  defaultLogo?:string,
};

function Logo({className, sx, url, smallerLogo, defaultLogo='/static/images/hrmetrics_full.png'}: LogoProps) {
  return (
    <Box
      component="img"
      alt="logo"
      src={url ? url : defaultLogo}
      style={{width: smallerLogo ? '250px' : '300px'}}
      className={className}
      sx={sx}
    />
  );
}

export default Logo;
