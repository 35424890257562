import axios from "axios";
import {nbAuthConfig} from "src/config";

export class AuthApi {
  static async authLoginCredentials(username: string, password: string) {
    let data = new URLSearchParams();
    data.append("client_id", nbAuthConfig.clientId);
    data.append("client_secret", nbAuthConfig.clientSecret);
    data.append("grant_type", "password");
    data.append("username", username);
    data.append("password", password);

    return await axios.post(nbAuthConfig.authDomain, data)
      .then(resp => {
        return resp.data;
      });
  }
}
