import {ReactNodeLike, Requireable} from 'prop-types';
import React, {useEffect} from 'react';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {Box} from '@material-ui/core';

export type MotionInViewProps = {
  children: Requireable<ReactNodeLike>,
  className: string,
  variants: any,
  transition: any,
  triggerOnce: boolean,
  threshold: number | number[] | undefined
}

function MotionInView(props: MotionInViewProps) {
  const {children, className, variants, transition, threshold, ...other} = props;
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: threshold ? threshold : 0,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      controls.start(Object.keys(variants)[1]).then();
    } else {
      controls.start(Object.keys(variants)[0]).then();
    }
  }, [controls, inView, variants]);

  return (
    <Box
      ref={ref}
      component={motion.div}
      initial={Object.keys(variants)[0]}
      animate={controls}
      variants={variants}
      transition={transition}
      className={className}
      {...other}
    >
      {children}
    </Box>
  );
}

export default MotionInView;
