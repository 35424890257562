import {applyMiddleware, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../reducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'authLoginReducer',
    'settings',
    'user',
    'company'
  ],
  blacklist: [
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  applyMiddleware(),
);

let persistor = persistStore(store);

export { store, persistor };