import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export  type ScrollToTopProps = {
  deps?: any[]
}

function ScrollToTop(props?: ScrollToTopProps) {
  const {deps} = props ?? {};
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }, [pathname, deps]);

  return null;
}

export default ScrollToTop;
