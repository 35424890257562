import React, { useEffect } from 'react';
import {ThemeConfig} from './theme';
import {Provider} from 'react-redux';
import {HashRouter, Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {store, persistor} from './redux/store/store';
import routes, {renderRoutes} from './routes';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import NotistackProvider from './components/NotistackProvider';
import {PersistGate} from 'redux-persist/lib/integration/react';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

const history = createBrowserHistory();

function App() {

  useEffect(() => {
    if((window.location.hash === ''
    || window.location.hash === '/#'
    || window.location.hash === '#/'
    || window.location.hash === '/#/'
    || window.location.hash.includes('/auth/login'))){

      if(!!localStorage.getItem('applicationReloaded')){
        localStorage.removeItem('applicationReloaded');
      }else{
        localStorage.setItem('applicationReloaded', 'We Need to reaload the application because we are facing a bug where sometime the login screen turns blank for no reason and then after a reload, everything works again.... "_"');
        window.location.reload();
      }
    }
  
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen className={""}/>} persistor={persistor}>
        <ThemeConfig>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <NotistackProvider>
              <HashRouter>
                <ScrollToTop/>
                <GoogleAnalytics/>
                {renderRoutes(routes)}
              </HashRouter>
            </NotistackProvider>
          </LocalizationProvider>
        </ThemeConfig>
      </PersistGate>
    </Provider>
  );
}

export default App;
