
import { useSelector } from "react-redux";

import { ResourceEmployeeGoalModel, ResourceEmployeeGoalModelPaginationResult, ResourceEmployeeGoalModelPaginationResultResultHandler, ResourceEmployeeListParams, ResourceEmployeeModel, ResourceEmployeeModelPaginationResult, ResourceEmployeeModelPaginationResultResultHandler, ResourceFeedbackListParams, ResourceFeedbackModel, ResourceFeedbackPayload } from "src/apis/data-contracts";
import { ProfileApi } from "src/apis/niki-api/api/ProfileApi";

export function useProfileController() {
  const { accessToken } = useSelector((state: any) => state.authLoginReducer);
  const profileApi = new ProfileApi(accessToken);


  return {

    resourceEmployeeDetail: async (resourceEmployeeId: string): Promise<ResourceEmployeeModel | undefined> => {
      
      return await profileApi.resourceEmployeeDetail(resourceEmployeeId).then((data: ResourceEmployeeModel | undefined) => {
        if (!data) return undefined;
        return data;
      })
        .catch((err: any) => {
          throw err;
        })
    },

    resourceFeedbackList: async (assessmentFeedbackListParams: ResourceFeedbackListParams): Promise<ResourceFeedbackModel[] | undefined> => {
      
      return await profileApi.resourceFeedbackList(assessmentFeedbackListParams).then((data: ResourceFeedbackModel[] | undefined) => {
        if (!data) return undefined;
        return data;
      })
        .catch((err: any) => {
          throw err;
        })
    },
    
    resourceEmployeeGoalList: async (resourceEmployeeId: string, limit?: number, offset?: number): Promise<ResourceEmployeeGoalModelPaginationResult | undefined> => {
      
      return await profileApi.resourceEmployeeGoalList({ResourceEmployeeId: resourceEmployeeId,  Limit: limit ?? 20, Offset: offset ?? 1 }).then((data: ResourceEmployeeGoalModelPaginationResult | undefined) => {
        if (!data) return undefined;
        return data;

      })
        .catch((err: any) => {
          throw err;
        })
    },

    resourceFeedbackCreate: async (data: ResourceFeedbackPayload) => {
      await profileApi.resourceFeedbackCreate(data)
    },

    resourceEmployeeGoalUpdate: async (params: ResourceEmployeeGoalModel): Promise<ResourceEmployeeGoalModel | undefined> => {
      
      return await profileApi.resourceEmployeeGoalUpdate(params).then((data: ResourceEmployeeGoalModel | undefined) => {
        if (!data) return undefined;
        return data;
      })
        .catch((err: any) => {
          throw err;
        })
    },

    resourceEmployeeGoalCreate: async (resourceEmployeeId: string, params: ResourceEmployeeGoalModel): Promise<ResourceEmployeeGoalModel | undefined> => {
      
      return await profileApi.resourceEmployeeGoalCreate(resourceEmployeeId, params).then((data: ResourceEmployeeGoalModel | undefined) => {
        if (!data) return undefined;
        return data;
      })
        .catch((err: any) => {
          throw err;
        })
    },
    
    resourceEmployeeGoalDelete: async (resourceEmployeeGoalId: string): Promise<any | undefined> => {
      
      return await profileApi.resourceEmployeeGoalDelete(resourceEmployeeGoalId).then((data: any | undefined) => {
        if (!data) return undefined;
        return data;
      })
        .catch((err: any) => {
          throw err;
        })
    },    
    
  };
}
