import clsx from 'clsx';
import React from 'react';
import {ReactNodeLike} from 'prop-types';
import {varFadeInUp} from './variants';
import {motion, AnimatePresence} from 'framer-motion';
import {makeStyles} from '@material-ui/core/styles';
import {Dialog} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper
  }
}));

export type DialogAnimateProps = {
  open: boolean,
  onClose: () => void,
  children: ReactNodeLike,
  className?: string,
  fullWidth?: boolean,
  maxWidth?: string,
  scroll?: string,
}

function DialogAnimate({open = false, onClose, children, className}: DialogAnimateProps) {
  const classes = useStyles();

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          // @ts-ignore
          PaperComponent={motion.div}
          // @ts-ignore
          PaperProps={{...varFadeInUp}}
          classes={{paper: classes.paper}}
          className={clsx(classes.root, className)}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}

export default DialogAnimate;
