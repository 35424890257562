import React from "react";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {PATH_APP, PATH_PAGE} from "src/routes/paths";
import {DateTime} from "luxon";
import {useAuthController} from "src/controllers/useAuthController";

export type GuardProtectProps = {
  children?: React.ReactNode;
};

function Home({children}: GuardProtectProps) {
  const authController = useAuthController();
  const {loggedIn, expiresAt} = useSelector((state: any) => state.authLoginReducer);

  if (DateTime.fromISO(expiresAt) < DateTime.now()) {
    return authController.logoff().then(() => {
      return <Redirect to={PATH_PAGE.auth.login}/>;
    });
  } else {
    if (loggedIn) {
      return <Redirect to={PATH_APP.root}/>;
    }
    if (!loggedIn) {
      return <Redirect to={PATH_PAGE.auth.login}/>;
    }
    return <>{children}</>;
  }

}

function GuardProtect({children}: GuardProtectProps) {
  const {loggedIn, expiresAt} = useSelector((state: any) => state.authLoginReducer);

  if (loggedIn && DateTime.fromISO(expiresAt) > DateTime.now()) {
    return <Redirect to={PATH_APP.root}/>;
  }

  return <>{children}</>;
}

export {GuardProtect, Home};
