import {DateTime} from "luxon";

export type AuthLoginReducerModel = {
  loggedIn: boolean;
  accessToken?: string;
  refreshToken?: string;
  expiresIn?: number;
  expiresAt?: DateTime;
};

const initialState: AuthLoginReducerModel = {
  loggedIn: false,
  accessToken: undefined,
  refreshToken: undefined,
  expiresIn: undefined,
  expiresAt: undefined,
};

const authLoginReducer = (
  state: AuthLoginReducerModel = initialState,
  action: any
): AuthLoginReducerModel => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        ...action.data,
        loggedIn: true,
      };
    }
    case "LOGOFF": {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default authLoginReducer;
