export type UserResourceReducerModel = {
  domain?: string;
  name?: string;
  status?: string;
  type?: string;
  logoUrl?: string;
};

const initialState: UserResourceReducerModel = {
  domain: undefined,
  name: undefined,
  status: undefined,
  type: undefined,
  logoUrl: undefined,
};

export const companyInfoReducer = (
  state: UserResourceReducerModel = initialState,
  action: any
): UserResourceReducerModel => {
  switch (action.type) {
    case "FETCH-COMPANY-INFO": {
      return {
        ...state,
        ...action.data
      };
    }
    case "COMPANY-INFO-LOGOFF": {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

