import {useDispatch} from 'react-redux';

export function useSettingsAction() {
  const dispatch = useDispatch();

  return {
    darkMode: () => {
      dispatch({type: "DARK"});
    },
    lightMode: () => {
      dispatch({type: "LIGHT"});
    },
    onLeft: () => {
      dispatch({type: "RTL"});
    },
    onRight: () => {
      dispatch({type: "LTR"});
    },
  }
}