import { AxiosMiddleware } from 'src/apis/niki-api/middleware/AxiosMiddleware';
import { nikiAssessmentApiConfig } from "../../../config";

import { ResourceEmployeeGoalListParams, ResourceEmployeeGoalModel, ResourceEmployeeGoalModelPaginationResult, ResourceEmployeeGoalModelPaginationResultResultHandler, ResourceEmployeeModel, ResourceFeedbackListParams, ResourceFeedbackModel, ResourceFeedbackPayload } from 'src/apis/data-contracts';

export class ProfileApi {
  constructor(accessToken: string) {
    this._axiosMiddleware = new AxiosMiddleware({
      baseURL: nikiAssessmentApiConfig.baseURL,
      headers: {
        "Authorization": `bearer ${accessToken}`
      }
    });
  }

  private _axiosMiddleware: AxiosMiddleware;

  async resourceEmployeeDetail(resourceEmployeeId: string): Promise<ResourceEmployeeModel | undefined> {

    return await this._axiosMiddleware.get(`/api/resource/employee/` + resourceEmployeeId);
  }


  async resourceFeedbackList(query: ResourceFeedbackListParams): Promise<ResourceFeedbackModel[] | undefined> {

    return await this._axiosMiddleware.post(`/api/resource/feedback/feedback`, query);
  }

  async resourceEmployeeGoalList(query: ResourceEmployeeGoalListParams): Promise<ResourceEmployeeGoalModelPaginationResult | undefined> {

    return await this._axiosMiddleware.post(`/api/resource/Employee/Goal`, query);
  }
 
 
  async resourceFeedbackCreate(data: ResourceFeedbackPayload) {
    await this._axiosMiddleware.post(`/api/resource/feedback`, data);
  }

  async resourceEmployeeGoalUpdate(query: ResourceEmployeeGoalModel): Promise<ResourceEmployeeGoalModel | undefined> {

    return await this._axiosMiddleware.put(`/api/resource/Employee/Goal`, query);
  }

  async resourceEmployeeGoalCreate(resourceEmployeeId: string, query: ResourceEmployeeGoalModel): Promise<ResourceEmployeeGoalModel | undefined> {

    return await this._axiosMiddleware.post(`/api/resource/Employee/Goal/${resourceEmployeeId}`, query);
  }

  async resourceEmployeeGoalDelete(resourceEmployeeGoalId: string): Promise<any | undefined> {

    return await this._axiosMiddleware.delete(`/api/resource/Employee/Goal/${resourceEmployeeGoalId}`);
  }
  
  

  

}
