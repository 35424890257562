export enum ResourceStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted'
}

export enum ResourceNodeTypeEnum {
  Leader = 'Leader',
  LeaderIndirect = 'LeaderIndirect',
  Staff = 'Staff',
  ClientExternal = 'ClientExternal',
  ClientInternal = 'ClientInternal'
}

export interface ResourceModel {
  resourceId?: string | null;
  resourceEmployeeId?: string | null;
  type?: ResourceTypeEnum;
  status?: ResourceStatusEnum;
  businessInfo?: BusinessInfoModel;
  personalInfo?: PersonalInfoModel;
  company?: CompanyModel;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
  emails?: EmailModel[] | null;
  resourceCandidate?: ResourceCandidateModel;
  resourceClient?: ResourceClientModel;
  resourceEmployee?: ResourceEmployeeModel;
  nodes?: ResourceNodeModel[] | null;
}

export interface ResourceEmployeeModel {
  resourceEmployeeId?: string | null;
  attributes?: ResourceEmployeeAttributeModel[] | null;
  resource?: ResourceModel;
}

export interface ResourceEmployeePayload {
  firstName?: string | null;
  lastName?: string | null;
  profileImageBase64?: string | null;
  department?: string | null;
  role?: string | null;
  branch?: string | null;
  team?: string | null;
  emailAddresses?: string[] | null;
  leaders?: string[] | null;
  leadersIndirect?: string[] | null;
  staff?: string[] | null;
  clientsExternal?: string[] | null;
  clientsInternal?: string[] | null;
}

export interface KeyValueModel {
  key?: string | null;
  value?: string | null;
}

export enum ResourceTypeEnum {
  Employee = 'Employee',
  Candidate = 'Candidate',
  Client = 'Client'
}

export interface BusinessInfoModel {
  businessInfoId?: string | null;
  legalName?: string | null;
  tradeName?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
}

export interface PersonalInfoModel {
  personalInfoId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  profileImageUrl?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
}

export interface EmailModel {
  emailId?: string | null;
  address?: string | null;
  confirmed?: boolean;
  main?: boolean;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
  status?: EmailStatusEnum;
  type?: EmailTypeEnum;
}

export interface CompanyModel {
  companyId?: string | null;
  name?: string | null;
  domain?: string | null;
  logoUrl?: string | null;
  type?: CompanyTypeEnum;
  status?: CompanyStatusEnum;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
}

export interface ResourceCandidateModel {
  resourceId?: string | null;
}

export interface ResourceClientModel {
  resourceClientId?: string | null;
  resource?: ResourceModel;
}

export interface ResourceNodeModel {
  type?: ResourceNodeTypeEnum;
  resource?: ResourceModel;
}


export interface ResourceEmployeeAttributeModel {
  resourceEmployeeAttributeId?: string | null;
  key?: string | null;
  value?: string | null;
}


export enum EmailStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum EmailTypeEnum {
  Personal = 'Personal',
  Professional = 'Professional'
}

export enum CompanyTypeEnum {
  Standard = 'Standard'
}


export enum CompanyStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Blocked = 'Blocked'
}

export interface ResourceEmployeeListParams {
  Name?: string;
  Email?: string;
  Leader?: string;
  Role?: string;
  Department?: string;
  Team?: string;
  Status?: ResourceStatusEnum;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceEmployeeModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ResourceEmployeeModelPaginationResult;
  message?: string | null;
}

export interface ResourceEmployeeModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ResourceEmployeeModel[] | null;
}

export interface PaginationDetail {
  /** @format int32 */
  limit?: number;

  /** @format int32 */
  offset?: number;

  /** @format int32 */
  totalCount?: number;

  /** @format int32 */
  totalPages?: number;
}

export enum ResourceFeedbackTypeEnum {
  Public = 'Public',
  Private = 'Private'
}

export interface ResourceFeedbackListParams {
  ToResourceEmployeeId?: string;
  FromResourceEmployeeId?: string;
}

export interface ResourceFeedbackModel {
  resourceFeedbackId?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string;
  message?: string | null;
  fromResourceId?: string | null;
  toResourceId?: string | null;
  type?: ResourceFeedbackTypeEnum;
  personalInfo?: PersonalInfoModel;
}

export interface ResourceEmployeeGoalModel {
  resourceEmployeeGoalId?: string | null;

  /** @format int32 */
  value?: number;
  description?: string | null;

  /** @format date-time */
  dueDate?: string | null;
  dueDateAlias?: string | null;
}

export interface ResourceFeedbackModelArrayResultHandler {
  succeeded?: boolean;
  data?: ResourceFeedbackModel[] | null;
  message?: string | null;
}

export interface ResourceEmployeeGoalListParams {
  ResourceEmployeeId?: string;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceEmployeeGoalModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ResourceEmployeeGoalModelPaginationResult;
  message?: string | null;
}

export interface ResourceEmployeeGoalModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ResourceEmployeeGoalModel[] | null;
}

export interface ResourceFeedbackPayload {
  message?: string | null;
  fromResourceEmployeeId?: string | null;
  toResourceEmployeeId?: string | null;
  type?: ResourceFeedbackTypeEnum;
}

export interface ResourceEmployeeGoalModel {
  resourceEmployeeGoalId?: string | null;

  /** @format int32 */
  value?: number;
  description?: string | null;

  /** @format date-time */
  dueDate?: string | null;
  dueDateAlias?: string | null;
}

export interface AssessmentModel {
  assessmentId?: string | null;
  name?: string | null;

  /** @format date-time */
  start?: string | null;

  /** @format date-time */
  end?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
  status?: AssessmentStatusEnum;
  type?: AssessmentTypeEnum;
  importedUrl?: string | null;

  /** @format date-time */
  importedDate?: string | null;
  importedName?: string | null;
  questionnaireId?: string | null;
  questionnaire?: QuestionnaireModel;

  /** @format int32 */
  maxAlternativeValue?: number;
}

export interface ChartModel {
  labels?: string[] | null;
  datasets?: ChartDatasets[] | null;

  /** @format double */
  average?: number;
}

export enum AssessmentStatusEnum {
  NotStarted = 'NotStarted',
  Started = 'Started',
  Completed = 'Completed'
}

export enum AssessmentTypeEnum {
  Performance = 'Performance',
  Skill = 'Skill',
  Imported = 'Imported'
}

export interface ChartDatasets {
  label?: string | null;
  backgroundColor?: string | null;
  borderColor?: string | null;
  pointBackgroundColor?: string | null;

  /** @format int32 */
  borderWidth?: number;
  data?: number[] | null;
  notes?: string[] | null;
}

export interface QuestionnaireModel {
  questionnaireId?: string | null;
  name?: string | null;
  title?: string | null;
  description?: string | null;
  instruction?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
  status?: QuestionnaireStatusEnum;
  type?: QuestionnaireTypeEnum;
  schema?: QuestionnaireSchemaEnum;
}

export enum QuestionnaireStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
  ReadOnly = 'ReadOnly'
}

export enum QuestionnaireTypeEnum {
  Standard = 'Standard',
  SystemModel = 'SystemModel',
  CompanyModel = 'CompanyModel'
}

export enum QuestionnaireSchemaEnum {
  Generic = 'Generic',
  Performance = 'Performance'
}

export interface MultiLanguageText {
  ptBR?: string | null;
  esAR?: string | null;
  enUS?: string | null;
}


export interface SkillAverageModel {
  /** @format int32 */
  skillId?: number;
  skillName?: string | null;

  /** @format int32 */
  value?: number;
  skillSelfAverage?: SkillAverageModel;
}

export enum LanguageTypeEnum {
  PtBR = "ptBR",
  EnUS = "enUS",
  EsAR = "esAR",
}

export interface ResourcePreferencesModel {
  language?: LanguageTypeEnum;
}

export interface ResourcePreferencesModelResultHandler {
  succeeded?: boolean;
  data?: ResourcePreferencesModel;
  message?: string | null;
}

export interface ResourceEmployeeListItemModel {
  resourceId?: string | null;
  resourceEmployeeId?: string | null;
  completeName?: string | null;
  email?: string | null;
  department?: string | null;
  role?: string | null;
  branch?: string | null;
  team?: string | null;
  status?: ResourceStatusEnum;
  profileImageUrl?: string | null;
}
export interface ResourceEmployeeListItemModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ResourceEmployeeListItemModel[] | null;
}
export interface ResourceEmployeeListItemModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ResourceEmployeeListItemModelPaginationResult;
  message?: string | null;
}