export type SettingsReducerModel = {
  themeMode: string;
  themeDirection: string;
}

const initialState: SettingsReducerModel = {
  themeMode: "light",
  themeDirection: "ltr",
};

const settingsReducer = (state: SettingsReducerModel = initialState, action: any): SettingsReducerModel => {
  switch (action.type) {
    case 'DARK': {
      return {
        ...state,
        themeMode: "dark"
      }
    }
    case 'LIGHT': {
      return {
        ...state,
        themeMode: "light"
      }
    }
    case 'RTL': {
      return {
        ...state,
        themeDirection: "rtl"
      }
    }
    case 'LTR': {
      return {
        ...state,
        themeDirection: "ltr"
      }
    }
    default: {
      return state;
    }
  }
}

export default settingsReducer;
