import clsx from 'clsx';
import React from 'react';
import {ReactNodeLike, Requireable} from 'prop-types';
import {motion} from 'framer-motion';
import {varWrapEnter} from './variants';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export type MotionContainerProps = {
  open: boolean,
  children: Requireable<ReactNodeLike>,
  className: string
}

function MotionContainer(props: MotionContainerProps) {
  const {open, children, className, ...other} = props;
  const classes = useStyles();

  return (
    <Box
      component={motion.div}
      initial={false}
      animate={open ? 'animate' : 'exit'}
      variants={varWrapEnter}
      className={clsx(classes.root, className)}
      {...other}
    >
      {children}
    </Box>
  );
}

export default MotionContainer;
